import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
const Layout = () => import('@/layout/index.vue')

// 静态路由
export const staticRoutes: Array<RouteRecordRaw> = [
  // home page
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/dashboard',
    meta: {
      icon: 'HomeFilled',
      hidden: false,
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/index.vue'),
        meta: {
          title: '首页',
          icon: 'HomeFilled',
          hidden: false
        }
      },
      {
        path: '/labor-contract-approve/:id?',
        name: 'laborContractApprove',
        component: () => import('@/views/ViewsComponents/labor/LaborContractApprove.vue'),
        meta: {
          hidden: true,
          title: '劳动合同审批',
        }
      },
    ]
  },
  // login
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      hidden: true,
    }
  },
  // 修改账单信息
  {
    path: '/bill_bank/update/:billId',
    name: 'BillBankUpdate',
    component: () => import('@/views/BillBankUpdate.vue'),
    meta: {
      hidden: true,
    }
  },
  // 404 Page
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
  // bind wechat
  {
    path: '/binding/:token',
    name: 'Binding',
    component: () => import('@/views/Binding.vue'),
    meta: {
      hidden: true,
      passCheck: true,
    }
  }
]

// 动态路由
export const asyncRoutes: Array<RouteRecordRaw> = [
  // 人资
  {
    path: '/human',
    component: Layout,
    name: 'human',
    meta: {
      hidden: false,
      icon: 'Avatar',
      title: "人资",
      // permissionStr: ['employee', 'labor_contract'],
    },
    children: [
      {
        path: '/human-reousces',
        name: 'humanReousces',
        component: () => import('@/views/HumanResources.vue'),
        meta: {
          title: '员工列表',
          hidden: false,
          permissionStr: ['employee'],
          icon: 'Avatar',
        }
      },
      {
        path: '/seat-Rate',
        name: 'seatRate',
        component: () => import('@/views/SeatRate.vue'),
        meta: {
          title: '工位使用',
          hidden: false,
          permissionStr: ['employee'],
          icon: 'Avatar',
        }
      },
      {
        path: '/human-maintian/:userId',
        name: 'userMaintain',
        component: () => import('@/views/HumanMaintain.vue'),
        meta: {
          hidden: true,
          permissionStr: ['employee'],
          title: '信息维护',
        }
      },
      {
        path: '/labor-contract',
        name: 'laborContract',
        component: () => import('@/views/LaborContract.vue'),
        meta: {
          hidden: false,
          permissionStr: ['labor_contract'],
          title: '劳动合同',
          icon: 'Avatar',
        }
      },
      {
        path: '/hr-other-Platforms',
        name: 'hr-otherPlatforms',
        component: () => import('@/views/OtherPlatforms.vue'),
        meta: {
          title: '其他平台权限',
          hidden: false,
          permissionStr: ['employee'],
        }
      },
      {
        path: '/employee-care',
        name: 'EmployeeCare',
        component: () => import('@/views/EmployeeCare.vue'),
        meta: {
          title: '员工关怀',
          hidden: false,
          permissionStr: ['employee_care'],
        }
      },
      {
        path: '/report-workovertime',
        name: 'WorkOvertime',
        component: () => import('@/views/ViewsComponents/report/WorkOvertime.vue'),
        meta: {
          title: '员工加班报表',
          hidden: false,
          permissionStr: ['work_overtime_report'],
        }
      },
    ]
  },
  // 人员变动
  /* {
    path: '/staff_changes',
    component: Layout,
    name: 'staff_changes',
    meta: {
      hidden: false,
      icon: '',
      title: "人员变动",
      permissionStr: ['employee'],
    },
    children: [
      {
        path: '/workspace',
        name: 'Workspace',
        component: () => import('@/views/WorkSpace.vue'),
        meta: {
          title: '工作台',
          hidden: false,
          permissionStr: ['employee'],
          icon: 'Menu',
        }
      },
    ]
  }, */
  // 薪酬
  {
    path: '/salary',
    component: Layout,
    name: 'SALARY',
    meta: {
      hidden: false,
      icon: 'Money',
      title: "薪酬",
    },
    children: [
      {
        path: '/salary-base',
        name: 'salaryBase',
        component: () => import('@/views/SalaryBase.vue'),
        meta: {
          title: '基础薪酬',
          permissionStr: ['salary_basic'],
          hidden: false,
        }
      },
      {
        path: '/offer-approval',
        name: 'OfferApproval',
        component: () => import('@/views/OfferApproval.vue'),
        meta: {
          title: 'Offer',
          permissionStr: ['offer'],
          hidden: false,
        }
      },
      {
        path: '/insurance',
        name: 'insurance',
        component: () => import('@/views/SalaryInsurance.vue'),
        meta: {
          title: '社保维护',
          permissionStr: ['salary_ins'],
          hidden: false,
        }
      },
      {
        path: '/insurance-pay-details',
        name: 'insurancePayDetails',
        component: () => import('@/views/InsurancePayDetails.vue'),
        meta: {
          title: '社保缴费明细',
          permissionStr: ['salary_ins_details'],
          hidden: false,
        }
      },
      {
        path: '/salary-import',
        name: 'salaryImport',
        component: () => import('@/views/SalaryImport.vue'),
        meta: {
          title: '薪酬数据导入',
          permissionStr: ['salary_import'],
          hidden: false,
        }
      },
      {
        path: '/cost-table',
        name: 'costTable',
        component: () => import('@/views/CostTable.vue'),
        meta: {
          title: '成本列表',
          permissionStr: ['salary_cost'],
          hidden: false,
        }
      },
      {
        path: '/financial-cost',
        name: 'financialCost',
        component: () => import('@/views/FinancialCost.vue'),
        meta: {
          title: '财务成本列表',
          permissionStr: ['salary_cost_finance'],
          hidden: false,
        }
      },
      {
        path: '/salary-modify',
        name: 'salaryModify',
        component: () => import('@/views/SalaryModify.vue'),
        meta: {
          title: '成本修改',
          permissionStr: ['salary_cost'],
          hidden: true,
        }
      },

      {
        path: '/allocation',
        component: ()=>{},
        name: 'Allocation',
        meta: {
          hidden: false,
          title:"工资发放",
          // permissionStr: ['salary_bill', 'payroll_detail'],
        },
        children: [
          {
            path: '/distribution-list',
            name: 'distributionList',
            component: () => import('@/views/SalaryDistributionList.vue'),
            meta: {
              title: '发放清单',
              permissionStr: ['salary_bill'],
              hidden: false,
            }
          },
          {
            path: '/salary-summary/:distributeId/:name/:multi',
            name: 'SalarySummary',
            component: () => import('@/views/ViewsComponents/salary/SalarySummary.vue'),
            meta: {
              title: '工资汇总表',
              permissionStr: ['salary_bill'],
              hidden: true,
            }
          },
          {
            path: '/respective-distribution',
            name: 'respectiveDistribution',
            component: () => import('@/views/ViewsComponents/salary/RespectiveDistribution.vue'),
            meta: {
              title: '工资发放清单',
              permissionStr: ['payroll_detail'],
              hidden: false,
            }
          },
          {
            path: '/distribution-summary',
            name: 'distributionSummary',
            component: () => import('@/views/DistributionSummary.vue'),
            meta: {
              title: '发放汇总',
              permissionStr: ['payroll_detail'],
              hidden: false,
            }
          },
        ]

      },
      // 发放清单公积金校验
      {
        path: '/distribution-fund-check/:id',
        name: 'distributionFundCheck',
        component: () => import('@/views/SalaryFundCheck.vue'),
        meta: {
          title: '发放清单公积金',
          permissionStr: ['salary_bill'],
          hidden: true,
        }
      },
      // 发放清单异动
      {
        path: '/distribution-compare',
        name: 'distributionCompare',
        component: () => import('@/views/ViewsComponents/SalaryCompare.vue'),
        meta: {
          title: '发放清单比较',
          permissionStr: ['salary_bill'],
          hidden: true,
        }
      },
      // 社保异动
      {
        path: '/insurance-compare',
        name: 'insuranceCompare',
        component: () => import('@/views/ViewsComponents/InsuranceCompare.vue'),
        meta: {
          title: '社保变动比较',
          permissionStr: ['salary_ins'],
          hidden: true,
        }
      },
      // 工资条列表
      {
        path: '/salary',
        name: 'salary',
        component: () => import('@/views/Salary.vue'),
        meta: {
          title: '工资',
          permissionStr: ['salary'],
          hidden: false,
        }
      },
      {
        path: '/user-bank-card',
        name: 'userBankCard',
        component: () => import('@/views/UserBankCard.vue'),
        meta: {
          title: '银行卡管理',
          permissionStr: ['bank_card'],
          hidden: false,
        }
      },
      {
        path: '/performance',
        name: 'performance',
        component: () => import('@/views/Performance.vue'),
        meta: {
          title: '绩效管理',
          permissionStr: ['performance'],
          hidden: false,
        }
      },
      {
        path: '/check',
        component: ()=>{},
        name: 'Check',
        meta: {
          hidden: false,
          title:"校验",
          // permissionStr: ['salary_check'],
        },
        children: [
          {
            path: '/salary-verification',
            name: 'SalaryVerification',
            component: () => import('@/views/SalaryVerification.vue'),
            meta: {
              title: '薪酬核对',
              permissionStr: ['salary_check'],
              hidden: false,
            }
          },
          {
            path: '/salary-check',
            name: 'salaryCheck',
            component: () => import('@/views/SalaryCheck.vue'),
            meta: {
              title: '薪酬校验',
              permissionStr: ['salary_check'],
              hidden: false,
            }
          },
          {
            path: '/security-check',
            name: 'securityCheck',
            component: () => import('@/views/SecurityCheck.vue'),
            meta: {
              title: '社保校验',
              permissionStr: ['salary_check'],
              hidden: false,
            }
          },
          {
            path: '/salary-record',
            name: 'salaryRecord',
            component: () => import('@/views/SalaryRecord.vue'),
            meta: {
              title: '差异记录',
              permissionStr: ['salary_check'],
              hidden: false,
            }
          },
        ]
      },
      {
        path: '/order-commission',
        name: 'orderCommission',
        component: () => import('@/views/OrderCommission.vue'),
        meta: {
          title: '订单提成',
          hidden: false,
          permissionStr: ['commission_manage'],

        }
      }
    ]
  },
  // 考勤
  {
    path: '/attendanceLayout',
    component: Layout,
    name: 'attendanceLayout',
    meta: {
      hidden: false,
      icon: 'Calendar',
      title: "考勤",
      permissionStr: ['checkin', 'checkin_salary'],
    },
    children: [
      {
        path: '/attendances',
        name: 'Attendances',
        component: () => import('@/views/Attendances.vue'),
        meta: {
          title: '考勤数据',
          hidden: false,
          icon: 'Calendar',
          permissionStr: ['checkin'],
        }
      },
      /* {
        path: '/holiday-count',
        name: 'holidayCount',
        component: () => import('@/views/HolidayCount.vue'),
        meta: {
          title: '假期余额',
          hidden: false,
        }
      }, */
      {
        path: '/attendance-statistics',
        name: 'attendanceStatistics',
        component: () => import('@/views/attendanceStatistics.vue'),
        meta: {
          title: '考勤统计',
          hidden: false,
          permissionStr: ['checkin_salary'],
        }
      },
      {
        path: '/attendance-abnormal',
        name: 'AttendanceAbnormal',
        component: () => import('@/views/AttendanceAbnormal.vue'),
        meta: {
          title: '异常报表',
          hidden: false,
          permissionStr: ['checkin_salary'],
        }
      },
      {
        path: '/attendance-rules',
        name: 'attendanceRules',
        component: () => import('@/views/AttendanceRules.vue'),
        meta: {
          title: '打卡规则',
          hidden: false,
          permissionStr: ['checkin'],
        }
      },
      {
        path: '/holiday-details',
        name: 'holidayDetails',
        component: () => import('@/views/HolidayDetails.vue'),
        meta: {
          title: '余额详情',
          hidden: true,
          permissionStr: ['checkin'],
        }
      },
    ]
  },
  // 我的
  {
    path: '/people',
    component: Layout,
    name: 'peopleLayout',
    meta: {
      hidden: false,
      icon: 'User',
      title: "我的",
    },
    children: [
      {
        path: '/att-sala-reward',
        name: '',
        component: () => null,
        meta: {
          title: '考勤/工资/报销',
          hidden: false,
        },
        children: [
          {
            path: '/personal-attendance',
            name: 'PersonalAttendance',
            component: () => import('@/views/PersonalAttendance.vue'),
            meta: {
              title: '个人考勤',
              hidden: false,
            }
          },
          {
            path: '/salary-details',
            name: 'SalaryDetails',
            component: () => import('@/views/SalaryDetails.vue'),
            meta: {
              title: '工资明细',
              hidden: false,
            }
          },
          {
            path: '/annual-salary',
            name: 'annualSalary',
            component: () => import('@/views/AnnualSalary.vue'),
            meta: {
              title: '年度工资',
              hidden: false,
            }
          },
          {
            path: '/personal-achievement',
            name: 'personalAchievement',
            component: () => import('@/views/PersonalAchievement.vue'),
            meta: {
              title: '个人绩效',
              hidden: false,
            }
          },
          {
            path: '/bank-card',
            name: 'BankCard',
            component: () => import('@/views/BankCard.vue'),
            meta: {
              title: '银行卡',
              hidden: false,
            }
          },
          {
            path: '/personal-invice',
            name: 'personalInvoice',
            component: () => import('@/views/PersonalInvoice.vue'),
            meta: {
              title: '发票凭证',
              hidden: false,
              icon: 'Fold',
            }
          },

          {
            path: '/my-project',
            name: 'myProject',
            component: () => import('@/views/myProject.vue'),
            meta: {
              title: '研发项目',
              hidden: false,
              icon: 'Fold',
              permissionStr: ['rd_project'],
            }
          },
          {
            path: '/my-assets',
            name: 'myAssets',
            component: () => import('@/views/MyOfficeAssets.vue'),
            meta: {
              title: '资产信息',
              hidden: false,
              icon: 'Fold',
            }
          },
        ]
      },
      {
        path: '/order-inv',
        name: '',
        component: () => null,
        meta: {
          title: '订单/发票',
          hidden: false,
        },
        children: [

          {
            path: '/customer-confirmation',
            name: 'customerConfirmation',
            component: () => import('@/views/CustomerConfirmation.vue'),
            meta: {
              title: '客户询证信息',
              hidden: false,
            }
          },          
            {
                path: '/project-deliver',
                name: 'projectDeliver',
                component: () => import('@/views/ProjectDeliver.vue'),
                meta: {
                    title: '项目交付',
                    hidden: false,
                    icon: 'Fold',
                }
            },
          {
            path: '/my-order-collection',
            name: 'MyOrderCollection',
            component: () => import('@/views/MyOrderCollection.vue'),
            meta: {
              title: '订单收款',
              hidden: false,
            }
          },
          {
            path: '/my-order-collection/extra',
            name: 'myOrderCollectionExtra',
            component: () => import('@/views/MyOrderCollectionExtra.vue'),
            meta: {
              hidden: true,
              title: '订单收款补充页面',
            }
          },
          {
            path: '/order-deliver',
            name: 'orderDeliver',
            component: () => import('@/views/OrderDeliver.vue'),
            meta: {
              title: '订单交付',
              hidden: false,
            }
          },
          {
            path: '/new_order_invoice',
            name: 'newInvoice',
            component: () => import('@/views/ViewsComponents/orderInvoice/NewInvoice.vue'),
            meta: {
              title: '订单开票',
              hidden: false,
            }
          },
            {
                path: '/salesman-invoice',
                name: 'salesmanInvoice',
                // component: () => import('@/views/SalesmanInvoice.vue'),
                component: () => import('@/views/SalesmanInvoice.vue'),
                meta: {
                    title: '发票勾销',
                    hidden: false,

            }
          },
          {
            path: '/my-order-commission',
            name: 'MyOrderCommission',
            component: () => import('@/views/ViewsComponents/commissionModle/CommissionDisbursement.vue'),
            meta: {
              title: '提成发放',
              hidden: false,
            }
          },
          {
            path: '/my-bad-debt',
            name: 'MyBadDebt',
            component: () => import('@/views/MyBadDebt.vue'),
            meta: {
              title: '坏账',
              hidden: false,
            }
          },
        ]
      },

      /* {
        path: '/order-invoice',
        component: ()=>{},
        name: 'OrderInvoice',
        meta: {
          hidden: false,
          title:"订单开票",
        },
        children: []
      }, */

    ]
  },
  // 财务
  {
    path: '/bank',
    component: Layout,
    name: 'BANK',
    meta: {
      hidden: false,
      icon: 'TrendCharts',
      title: "财务",
      // permissionStr: ['pay_cost_ctc', 'receivable_balance', 'tr', 'bank_account', 'translation_verify', 'payable', 'accountant', 'accounts_receivable', 'order_query', 'confirmation_letter', 'invoice_query', 'balance_list', 'verfiy_invoice', 'accountant_approval', 'financial_customer'],
    },
    children: [
      {
        path: '/bank-flow',
        name: 'bankFlow',
        component: () => import('@/views/BankFlow.vue'),
        meta: {
          title: '交易记录',
          icon: 'TrendCharts',
          permissionStr: ['tr'],
          hidden: false,
        }
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/BankAccount.vue'),
        meta: {
          title: '银行账号',
          permissionStr: ['bank_account'],
          hidden: false,
        }
      },
      {
        path: '/pay-account',
        name: 'payAccount',
        component: () => import('@/views/PayAccount.vue'),
        meta: {
          title: '翻译收入核销',
          permissionStr: ['translation_verify'],
          hidden: false,
        }
      },
      {
        path: '/verify-authorization',
        name: 'verifyAuthorization',
        component: () => import('@/views/VerifyAuthorization.vue'),
        meta: {
          title: '流水冲销授权',
          permissionStr: ['verfiy_invoice'],
          hidden: false,
        }
      },
      {
        path: '/Receivable',
        component: ()=>{},
        name: 'Receivable',
        meta: {
          hidden: false,
          title:"收款",
          // permissionStr: ['accounts_receivable', 'order_query', 'invoice_query', 'verfiy_invoice', 'receivable_balance', 'balance_list']
        },
        children: [
          // {
          //   path: '/vat/invoice',
          //   name: 'vatInovoice',
          //   component: () => import('@/views/VatInvoice.vue'),
          //   meta: {
          //     title: '订单开票',
          //     permissionStr: ['order_create'],
          //     hidden: false,
          //   }
          // },
          {
            path: '/accounts-receivable',
            name: 'accountsReceivable',
            component: () => import('@/views/AccountsReceivable.vue'),
            meta: {
              title: '应收账款',
              permissionStr: ['accounts_receivable'],
              hidden: false,
            }
          },
          {
            path: '/order-query',
            name: 'orderQuery',
            component: () => import('@/views/ViewsComponents/financialReceivable/OrderQueryIndex.vue'),
            meta: {
              title: '订单查询',
              permissionStr: ['order_query'],
              hidden: false,
            },
            beforeEnter: (to, from) => {
              to.meta.beforePage = from.path
            },
          },
          {
            path: '/order-record',
            name: 'orderRecord',
            component: () => import('@/views/OrderRecord.vue'),
            meta: {
              title: '订单变更',
              permissionStr: ['order_query'],
              hidden: false,
            },
          },
          {
            path: '/invoice',
            name: 'invoice',
            component: () => import('@/views/Invoice.vue'),
            meta: {
              title: '发票查询',
              permissionStr: ['invoice_query'],
              hidden: false,
            }
          },
          /* {
            path: '/flow-invoice-verify',
            name: 'flowInvoiceVerify',
            component: () => import('@/views/FlowInvoiceVerify.vue'),
            meta: {
              title: '流水发票',
              permissionStr: ['verfiy_invoice'],
              hidden: false,
            }
          }, */
          {
            path: '/flow-order-verify',
            name: 'floOrderVerify',
            component: () => import('@/views/FlowOrderVerify.vue'),
            meta: {
              title: '流水订单',
              permissionStr: ['verfiy_invoice'],
              hidden: false,
            }
          },
          {
            path: '/receivable-balance',
            name: 'receivableBalance',
            component: () => import('@/views/ReceivableBalance.vue'),
            meta: {
              title: '科目余额',
              permissionStr: ['receivable_balance'],
              hidden: false,
            }
          },
          {
            path: '/balance-list',
            name: 'balanceList',
            component: () => import('@/views/BalanceList.vue'),
            meta: {
              title: '勾销记录',
              permissionStr: ['balance_list'],
              hidden: false,
            }
          },
        ]
      },
      {
        path: '/Payable',
        component: ()=>{},
        name: 'Payable',
        meta: {
          hidden: false,
          title:"付款",
          // permissionStr: ['pay_cost_balance', 'pay_cost_cost', 'pay_cost_ctc', 'pay_cost_rt']
        },
        children: [
          {
            path: '/accounts-payable',
            name: 'accountsPayable',
            component: () => import('@/views/AccountsPayable.vue'),
            meta: {
              title: '应付账款',
              permissionStr: ['pay_cost_cost'],
              hidden: false,
            }
          },
          {
            path: '/balance',
            name: 'balance',
            component: () => import('@/views/Balance.vue'),
            meta: {
              title: '科目余额',
              permissionStr: ['pay_cost_balance'],
              hidden: false,
            }
          },
          {
            path: '/rt',
            name: 'Rt',
            component: () => import('@/views/Rt.vue'),
            meta: {
              title: '成本',
              permissionStr: ['pay_cost_rt'],
              hidden: false,
            }
          },
          {
            path: '/convert_to_cash',
            name: 'ConvertToCash',
            component: () => import('@/views/ConvertToCash.vue'),
            meta: {
              title: '提现',
              permissionStr: ['pay_cost_ctc'],
              hidden: false,
            }
          },
          {
            path: '/convert_to_cash_detail',
            name: 'ConvertToCashDetail',
            component: () => import('@/views/ConvertToCashDetail.vue'),
            meta: {
              title: '提现明细',
              permissionStr: ['pay_cost_ctc'],
              hidden: false,
            }
          },
          {
            path: '/convert_to_cash_package',
            name: 'ConvertToCashPackage',
            component: () => import('@/views/ConvertToCashPackage.vue'),
            meta: {
              title: '提现支付包',
              permissionStr: ['pay_cost_ctc'],
              hidden: false,
            }
          },
          {
            path: '/cost_match',
            name: 'CostMatch',
            component: () => import('@/views/CostMatch.vue'),
            meta: {
              title: '提现单冲销交易流水',
              permissionStr: ['pay_cost_ctc'],
              hidden: false,
            }
          },
        ]
      },
      {
        path: '/income-cost',
        name: 'incomeCost',
        component: () => import('@/views/IncomeCost.vue'),
        meta: {
          title: '收入成本',
          permissionStr: ['accountant'],
          hidden: false,
        }
      },
      {
        path: '/internal-transaction',
        name: 'internalTransaction',
        component: () => import('@/views/InternalTransaction.vue'),
        meta: {
          title: '内部交易',
          permissionStr: ['accountant'],
          hidden: false,
        }
      },
      /*  {
         path: '/profit',
         name: 'profit',
         component: () => import('@/views/Profit.vue'),
         meta: {
           title: '利润差异',
           permissionStr: ['accountant'],
           hidden: false,
         }
       }, */
      {
        path: '/voucher',
        component: ()=>{},
        name: 'Voucher',
        meta: {
          hidden: false,
          title:"凭证",
          // permissionStr: ['voucher']
        },
        children: [
          {
            path: '/voucher-subject',
            name: 'VoucherSubject',
            component: () => import('@/views/VoucherSubject.vue'),
            meta: {
              hidden: false,
              title: '凭证科目',
              permissionStr: ['voucher'],
            }
          },
          {
            path: '/voucher-query',
            name: 'VoucherQuery',
            component: () => import('@/views/VoucherQuery.vue'),
            meta: {
              hidden: false,
              title: '凭证查询',
              permissionStr: ['voucher'],
            }
          },
          {
            path: '/cash-flow-subjec',
            name: 'CashFlowSubject',
            component: () => import('@/views/CashFlowSubject.vue'),
            meta: {
              hidden: false,
              title: '现金流科目',
              permissionStr: ['voucher'],
            }
          },
        ]
      },
      {
        path: '/confirmation-letter',
        name: 'confirmationLetter',
        component: () => import('@/views/ConfirmationLetter.vue'),
        meta: {
          title: '询证函',
          permissionStr: ['confirmation_letter'],
          hidden: false,
        }
      },
      {
        path: '/order-match',
        name: 'orderMatch',
        component: () => import('@/views/OrderMatch.vue'),
        meta: {
          title: '订单匹配',
          permissionStr: ['confirmation_letter'],
          hidden: false,
        }
      },
      {
        path: '/order-category-maintenance',
        name: 'orderCategoryMaintenance',
        component: () => import('@/views/OrderCategoryMaintenance.vue'),
        meta: {
          title: '订单类别',
          icon: 'DataLine',
          permissionStr: ['accountant'],
          hidden: false,
        }
      },
      {
        path: '/invoice-approve',
        name: 'invoiceApprove',
        component: () => import('@/views/ReimbursementApprove.vue'),
        meta: {
          title: '单据审批',
          permissionStr: ['accountant_approval'],
          hidden: false,
          icon: 'Fold',
        }
      },
      {
        path: '/financial-customer',
        name: 'financialCustomer',
        component: () => import('@/views/FinancialCustomer.vue'),
        meta: {
          title: '客户管理',
          permissionStr: ['financial_customer'],
          hidden: false,
        }
      },
      {
        path: '/financial-customer-details/:id',
        name: 'financialCustomerDetails',
        component: () => import('@/views/ViewsComponents/financial/CustomerDetails.vue'),
        meta: {
          title: '客户管理详情',
          permissionStr: ['financial_customer'],
          hidden: true,
        }
      },
      {
        path: '/order/payment_voucher',
        name: 'orderPaymentVoucher',
        component: () => import('@/views/ViewsComponents/orderPaymentVoucher/paymentVocher.vue'),
        meta: {
          title: '代付凭证',
          permissionStr: ['order_payment_voucher'],
          hidden: false,
        }
      },
      {
        path: '/bad-debt',
        name: 'badDebt',
        component: () => import('@/views/BadDebt.vue'),
        meta: {
          title: '坏账',
          permissionStr: ['finance_bad_debt'],
          hidden: false,
        }
      }
    ]
  },
  // 支付
  {
    path: '/reimbursement',
    component: Layout,
    name: 'Expense',
    meta: {
      hidden: false,
      icon: 'Wallet',
      title:"支付",
      // permissionStr: ['pay_pool', 'pay_records', 'pay_approved', 'hr_approved', 'fd_approved']
    },
    children:[
      {
        path: '/pay-pool',
        name: 'PaymentPool',
        component: () => import('@/views/ExpenseSystem.vue'),
        meta: {
          title: '支付池',
          // icon: 'Money',
          hidden: false,
          permissionStr: ['pay_pool'],
        }
      },
      {
        path: '/pay/history',
        name: 'PayHistory',
        component: () => import('@/views/PayHistory.vue'),
        meta: {
          title: '支付记录',
          // icon: 'Money',
          hidden: false,
          permissionStr: ['pay_records'],
        }
      },
      {
        path: '/pay',
        // path: '/pay/:ids',
        name: 'Pay',
        component: () => import('@/views/Pay.vue'),
        hidden: true,
        meta: {
          permissionStr: ['pay_pool'],
          hidden: true,
        }
      },
      // {
      //     path: '/approval',
      //     name: 'Approval',
      //     component: () => import('@/views/Approval.vue'),
      //     meta: {
      //       title:"审核",
      //       permissionStr: ['pay_approved'],
      //       hidden: false,
      //     }
      // },
      {
        path: '/',
        component: ()=>{},
        name: 'Approval',
        meta: {
          hidden: false,
          title:"审核",
          // permissionStr: ['hr_approved', 'fd_approved']
        },
        children:[
          {
            path: '/hr-review',
            name: 'Approval',
            component: () => import('@/views/Approval.vue'),
            meta: {
              title:"HR审核",
              permissionStr: ['hr_approved'],
              hidden: false,
            }
          },
          // {
          //   path: '/approval/history/hr',
          //   name: 'HrApproval',
          //   component: () => import('@/views/HrApprovalHistory.vue'),
          //   meta: {
          //     title:"HR审核记录",
          //     permissionStr: ['pay_approved'],
          //     hidden: false,
          //   }
          // },
          {
            path: '/accountant-review',
            name: 'caiwuApproval',
            component: () => import('@/views/FinanceApproval.vue'),
            meta: {
              title:"财务审核",
              permissionStr: ['fd_approved'],
              hidden: false,
            }
          },
        ]
      },
      {
        path: '/outside-account',
        name: 'outsideAccount',
        component: () => import('@/views/OutsideAccount.vue'),
        meta: {
          title: '外部收款账户',
          hidden: false,
          permissionStr: ['pay_pool'],
        }
      },
      {
        path: '/approval/detail/:id',
        name: 'ApprovalDetail',
        component: () => import('@/views/ApprovalHistory.vue'),
        hidden: true,
        meta: {
          permissionStr: ['fd_approved'],
          hidden: true,
        }
      },
      {
        path: '/salary-type',
        name: 'salaryType',
        component: () => import('@/views/SalaryType.vue'),
        meta: {
          title: '工资类别',
          icon: 'Money',
          hidden: false,
          permissionStr: ['pay_salary_category'],
        }
      },

    ]
  },
  // 租赁
  {
    path: '/lease',
    component: Layout,
    name: 'LEASE',
    meta: {
      hidden: false,
      icon: 'School',
      title: "租赁",
      // permissionStr: ['house_lease', 'lease_cost', 'house_lease_leader'],
    },
    children: [
      {
        path: '/lease-table',
        name: 'leaseTabel',
        component: () => import('@/views/LeaseTabel.vue'),
        meta: {
          title: '租赁列表',
          hidden: false,
          permissionStr: ['house_lease'],
        }
      },
      {
        path: '/lease-bill',
        name: 'leaseBill',
        component: () => import('@/views/LeaseBill.vue'),
        meta: {
          hidden: true,
          permissionStr: ['house_lease'],
          title: '租赁账单',
        }
      },
      {
        path: '/lease-details/:id/:lessee',
        name: 'leaseDetails',
        component: () => import('@/views/LeaseDetails.vue'),
        meta: {
          hidden: true,
          permissionStr: ['house_lease'],
          title: '租赁明细',
        }
      },
      {
        path: '/lease-principal/:id',
        name: 'leasePrincipal',
        component: () => import('@/views/LeasePrincipal.vue'),
        meta: {
          hidden: true,
          permissionStr: ['house_lease_leader'],
          title: '申请人维护',
        }
      },
      {
        path: '/lease-cost/:orgId/:date/:orgName',
        name: 'leaseCost',
        component: () => import('@/views/LeaseCost.vue'),
        meta: {
          hidden: true,
          permissionStr: ['lease_cost'],
          title: '员工租赁成本',
        }
      },
      {
        path: '/lease-team-cost/:orgId/:orgName',
        name: 'leaseTeamCost',
        component: () => import('@/views/LeaseTeamCost.vue'),
        meta: {
          hidden: true,
          permissionStr: ['lease_cost'],
          title: '团队租赁成本',
        }
      },
      {
        path: '/lease-cost-query',
        name: 'leaseCostQuery',
        component: () => import('@/views/LeaseCostQuery.vue'),
        meta: {
          hidden: false,
          permissionStr: ['lease_cost'],
          title: '成本查询',
        }
      },
      {
        path: '/lease-annual-cost',
        name: 'leaseAnnualCost',
        component: () => import('@/views/LeaseAnnualCost.vue'),
        meta: {
          hidden: false,
          permissionStr: ['lease_cost'],
          title: '年度租赁成本',
        }
      },

    ]
  },
  // 项目成本
  {
    path: '/projectLayout',
    component: Layout,
    name: 'project',
    meta: {
      hidden: false,
      icon: 'Fold',
      title: "研发项目",
      // permissionStr: ['project', 'project_time_tracking', 'project_members'],
    },
    children: [
      {
        path: '/project',
        name: 'projectCost',
        component: () => import('@/views/Project.vue'),
        meta: {
          title: '项目成本',
          hidden: false,
          permissionStr: ['project'],
          icon: 'Fold',
        }
      },
      {
        path: '/all-project',
        name: 'allProject',
        component: () => import('@/views/AllProject.vue'),
        meta: {
          title: '项目工时',
          hidden: false,
          icon: 'Fold',
          permissionStr: ['project_time_tracking'],
        }
      },
      {
        path: '/project-maintain',
        name: 'projectMaintain',
        component: () => import('@/views/ProjectMaintain.vue'),
        meta: {
          title: '项目成员',
          hidden: false,
          permissionStr: ['project_members'],

        }
      },
    ]
  },
  {
    path: '/office-asset',
    component: Layout,
    name: 'OfficeAsset',
    redirect: '/office-asset/manage',
    meta: {
      hidden: false,
      icon: 'Setting',
      title: "资产管理",
    },
    children: [
      {
        path: '/office-asset/statistical-info',
        name: 'OfficeAssetStatisticalInfo',
        component: () => import('@/views/OfficeAssetStatisticalInfo.vue'),
        meta: {
          title: '统计与查询',
          hidden: false,
          icon: 'TrendCharts',
          permissionStr: ['office_asset_manage'],
        }
      },
      {
        path: '/office-asset/manage',
        name: 'OfficeAssetManage',
        component: () => import('@/views/OfficeAssetChange.vue'),
        meta: {
          title: '资产变动',
          hidden: false,
          icon: 'TrendCharts',
          permissionStr: ['office_asset_manage'],
        }
      },
      {
        path: '/office-asset/inventory',
        name: 'OfficeAssetInventory',
        component: () => import('@/views/OfficeAssetInventory.vue'),
        meta: {
          title: '资产盘点',
          hidden: false,
          icon: 'TrendCharts',
          permissionStr: ['office_asset_admin'],
        }
      },
      {
        path: '/office-asset/depreciation',
        name: 'OfficeAssetDepreciation',
        component: () => import('@/views/OfficeAssetDepreciation.vue'),
        meta: {
          title: '资产折旧',
          hidden: false,
          icon: 'TrendCharts',
          permissionStr: ['office_asset_admin'],
        }
      },
      {
        path: '/office-asset/category',
        name: 'OfficeAssetCategory',
        component: () => import('@/views/OfficeAssetCategory.vue'),
        meta: {
          title: '资产类别维护',
          hidden: false,
          icon: 'TrendCharts',
          permissionStr: ['office_asset_admin'],
        }
      }
    ]

  },
  {
    path: '/flowableLayout',
    name: 'flowableLayout',
    component: Layout,
    redirect: '/flowable',
    meta: {
      icon: 'View',
      hidden: false,
      title:'审批',
      // permissionStr: ['flowable'],
    },
    children: [
      {
        path: '/flowable',
        name: 'flowable',
        component: () => import('@/views/Flowable.vue'),
        meta: {
          title: '审批',
          icon: 'View',
          // permissionStr: ['flowable'],
          hidden: false,
        }
      },
    ]
  },
  {
    path: '/dataChartLayout',
    component: Layout,
    name: 'dataChartLayout',
    meta: {
      hidden: false,
      icon: 'DataLine',
      title: "数据报表",
    },
    children: [
      {
        path: '/report-basic',
        name: 'reportBasic',
        component: () => import('@/views/ViewsComponents/report/Basic.vue'),
        meta: {
          title: '基础数据报表',
          hidden: false,
          permissionStr: ['basic_report'],
          icon: 'DataLine',
        }
      },
      {
        path: '/report-custom',
        name: 'reportCustom',
        component: () => import('@/views/ViewsComponents/report/Custom.vue'),
        meta: {
          title: '客户数据报表',
          hidden: false,
          permissionStr: ['customer_report'],
          icon: 'DataLine',
        }
      },
      {
        path: '/mte-report',
        name: 'mteReport',
        component: () => import('@/views/MteReport.vue'),
        meta: {
          title: 'MTE报表',
          hidden: false,
          permissionStr: ['mte_report'],
          icon: 'DataLine',
        }
      },
      {
        path: '/report-order_trend',
        name: 'reportOrderTrend',
        component: () => import('@/views/ViewsComponents/report/OrderTrend.vue'),
        meta: {
          title: '订单趋势报表',
          hidden: false,
          permissionStr: ['order_trend_report'],
          icon: 'DataLine',
        }
      },
      {
        path: '/report-translator-costing',
        name: 'TranslatorCosting',
        component: () => import('@/views/ViewsComponents/report/TranslatorCosting.vue'),
        meta: {
          title: '内部译员成本分析',
          hidden: false,
          permissionStr: ['translator_costing'],
          icon: 'DataLine',
        }
      },
      {
        path: '/comission',
        name: 'comission',
        component: () => import('@/views/Commission.vue'),
        meta: {
          title: '订单成本明细报表',
          hidden: false,
          icon: 'DataLine',
        }
      }
    ]
  },
  {
    path: '/orgBalanceLayout',
    component: Layout,
    name: 'orgBalanceLayout',
    meta: {
      hidden: false,
      icon: 'Tickets',
      title: "团队利润",
      // permissionStr: ['org_balance', 'org_balance_manage','efficiency_translate'],
    },
    children: [
      {
        path: '/org-balance',
        name: 'orgBalance',
        component: () => import('@/views/orgBalance.vue'),
        meta: {
          title: '团队利润',
          hidden: false,
          permissionStr: ['org_balance'],
          icon: 'Tickets',
        }
      },
      {
        path: '/org-gross-balance',
        name: 'orgGrossBalance',
        component: () => import('@/views/orgGrossBalance.vue'),
        meta: {
          title: '团队利润总表',
          hidden: false,
          permissionStr: ['org_balance_manage'],
          icon: 'Tickets',
        }
      },
      {
        path: '/translator-efficiency',
        name: 'TranslatorEff',
        component: () => import('@/views/ViewsComponents/LanguageDept/TranslatorEff.vue'),
        meta: {
          title: '语言部译员效率值',
          hidden: false,
          permissionStr: ['efficiency_translate'],
          icon: 'Tickets',
        }
      },
    ]
  },
  // 文档管理
  {
    path: '/Marketing',
    component: Layout,
    name: 'Marketing',
    meta: {
      hidden: false,
      icon: 'Files',
      title: "文档管理",
      englishTitle:'Document Box'
      // permissionStr: ['drive'],
    },
    children: [
      {
        path: '/drive/%E6%94%BF%E7%AD%96%E5%88%B6%E5%BA%A6/:link*',
        alias: ['/drive/政策制度/:link*'],
        name: 'propaganda',
        component: () => import('@/views/Propaganda.vue'),
        meta: {
          path: '/drive/%E6%94%BF%E7%AD%96%E5%88%B6%E5%BA%A6',
          title: '政策制度',
          englishTitle:'Policies & Regulations',
          icon: 'Files',
          hidden: false,
          // permissionStr: ['drive'],
        }
      },
      {
        path: '/drive/%E9%94%80%E5%94%AE%E5%A5%97%E4%BB%B6/:link*',
        alias: ['/drive/销售套件/:link*'],
        name: 'tender',
        component: () => import('@/views/Tender.vue'),
        meta: {
          path: '/drive/%E9%94%80%E5%94%AE%E5%A5%97%E4%BB%B6',
          title: '销售套件',
          englishTitle:'Sales Kit',
          icon: 'Files',
          hidden: false,
          // permissionStr: ['drive'],
        }
      },
      {
        path: '/mars-resources',
        name: 'marsResources',
        component: () => import('@/views/MarsResources.vue'),
        meta: {
          title: '火星资源',
          englishTitle:'Mars Resources',
          icon: 'Files',
          hidden: false,
          // permissionStr: ['drive'],
        }
      },
      {
        path: '/drive/download-record',
        name: 'downloadRecord',
        component: () => import('@/views/DownloadRecord.vue'),
        meta: {
          title: '下载记录',
          englishTitle:'Download History',
          icon: 'Files',
          hidden: false,
          permissionStr: ['drive'],
          keep: false
        }
      },
      {
        path: '/drive/recycle-bin',
        name: 'recycleBin',
        component: () => import('@/views/RecycleBin.vue'),
        meta: {
          title: '回收站',
          englishTitle:'Recycle Bin',
          icon: 'Files',
          hidden: false,
          // permissionStr: ['drive'],
        }
      },
    ]
  },
  // Customer relationship management

  // {
  //   path: '/crm',
  //   component: Layout,
  //   name: 'crm',
  //   meta: {
  //     hidden: false,
  //     icon: 'UserFilled',
  //     // permissionStr: ['crm', 'crm_customer', 'crm_probability', 'crm_order'],
  //     title: "客户关系",
  //   },
  //   children: [
  //     {
  //       path: '/sales-bulletin',
  //       name: 'SalesBulletin',
  //       component: () => import('@/views/ViewsComponents/crm/SalesBulletin.vue'),
  //       meta: {
  //         title: '销售看板',
  //         permissionStr: ['crm_customer', 'crm'],
  //         icon: 'UserFilled',
  //         hidden: false,
  //       }
  //     },
  //     {
  //       path: '/potential-customer',
  //       name: 'potentialCustomer',
  //       component: () => import('@/views/ViewsComponents/crm/PotentialCustomer.vue'),
  //       meta: {
  //         title: '潜客管理',
  //         permissionStr: ['crm_customer', 'crm'],
  //         icon: 'UserFilled',
  //         hidden: false,
  //       }
  //     },
  //     {
  //       path: '/booking-customer',
  //       name: 'bookingCustomer',
  //       component: () => import('@/views/ViewsComponents/crm/BookingCustomer.vue'),
  //       meta: {
  //         title: '预客管理',
  //         permissionStr: ['crm_customer', 'crm'],
  //         icon: 'UserFilled',
  //         hidden: false,
  //       }
  //     },
  //     {
  //       path: '/record-business',
  //       name: 'recordBusiness',
  //       component: () => import('@/views/ViewsComponents/crm/RecordBusiness.vue'),
  //       meta: {
  //         title: '记录商机',
  //         icon: 'UserFilled',
  //         permissionStr: ['crm_probability', 'crm'],
  //         hidden: false,
  //       }
  //     },
  //     {
  //       path: '/custmers-management',
  //       name: 'custmersManagement',
  //       component: () => import('@/views/ViewsComponents/crm/CustmersManagement.vue'),
  //       meta: {
  //         title: '客户管理',
  //         icon: 'UserFilled',
  //         permissionStr: ['crm_customer', 'crm'],
  //         hidden: false,
  //       }
  //     },
  //     {
  //       path: '/followe-logs',
  //       name: 'FolloweLogs',
  //       component: () => import('@/views/ViewsComponents/crm/FolloweLogs.vue'),
  //       meta: {
  //         title: '跟进记录',
  //         icon: 'UserFilled',
  //         permissionStr: ['crm_customer', 'crm'],
  //         hidden: false,
  //       }
  //     },
  //     {
  //       path: '/order-list/:name?',
  //       name: 'orderList',
  //       // alias: ['/order-list/:name'],
  //       component: () => import('@/views/ViewsComponents/crm/OrderList.vue'),
  //       meta: {
  //         path: '/order-list',
  //         title: '订单列表',
  //         icon: 'UserFilled',
  //         permissionStr: ['crm_order', 'crm'],
  //         hidden: false,
  //       }
  //     },
  //
  //     /* {
  //       path: '/customer-configuration',
  //       name: 'customerConfiguration',
  //       component: () => import('@/views/CustomerConfiguration.vue'),
  //       meta: {
  //         title: '参数配置',
  //         icon: 'UserFilled',
  //         hidden: false,
  //       }
  //     }, */
  //   ]
  // },
  // 支付与服务
  {
    path: '/tickets',
    component: Layout,
    name: 'tickets',
    meta: {
      hidden: false,
      icon: 'Service',
      title: "支持与服务",
    },
    children:[
      {
        path: '/ticket/create',
        name: 'TicketCreate',
        component: () => import('@/views/ViewsComponents/Tickets/NewTicket.vue'),
        meta: {
          title: '提交工单',
          icon: 'Service',
          hidden: false,
        }
      },
      {
        path: '/ticket',
        name: 'TicketLists',
        component: () => import('@/views/ViewsComponents/Tickets/TicketLists.vue'),
        meta: {
          title: '我的工单',
          icon: 'Service',
          hidden: false,
        },
      },
      {
        path: '/ticket/submit/:id',
        name: 'SubmitTicketDetail',
        component: () => import('@/views/ViewsComponents/Tickets/TicketDetail.vue'),
        meta: {
          title: '我提交的工单',
          icon: 'Service',
          hidden: true,
        },
      },
      {
        path: '/ticket/responsible/:id',
        name: 'ResponsibleTicketDetail',
        component: () => import('@/views/ViewsComponents/Tickets/TicketDetail.vue'),
        meta: {
          title: '我负责的工单',
          icon: 'Service',
          hidden: true,
        },
      },
      {
        path: '/ticket/all/:id',
        name: 'AllTicketDetail',
        component: () => import('@/views/ViewsComponents/Tickets/TicketDetail.vue'),
        meta: {
          title: '所有工单',
          icon: 'Service',
          hidden: true,
        },
      },
      {
        path: '/ticket/config',
        name: 'TicketConfig',
        component: () => import('@/views/ViewsComponents/Tickets/TicketConfig.vue'),
        meta: {
          title: '工单配置',
          icon: 'Service',
          hidden: false,
        },
      },

    ]
  },
  {
    path: '/toolboxLayout',
    name: 'toolboxLayout',
    component: Layout,
    redirect: '/toolbox',
    meta: {
      icon: 'Tools',
      hidden: false,
      title:'工具箱',
      // permissionStr: ['flowable'],
    },
    children: [
      {
        path: '/toolbox',
        name: 'toolbox',
        component: () => import('@/views/ToolBox.vue'),
        meta: {
          title: '工具箱',
          icon: 'Tools',
          // permissionStr: ['flowable'],
          hidden: false,
        }
      },
    ]
  },

  // 组织架构
  {
    path: '/system',
    component: Layout,
    name: 'SYSTEM',
    meta: {
      hidden: false,
      icon: 'Management',
      title: "组织架构",
      // permissionStr: ['order_category', 'rate_maintenance', 'financial_maintenance', 'reimburse', 'customer_list', 'company', 'org', 'salary_project', 'job', 'bank_card', 'translator', 'budget'],
    },
    children: [
      {
        path: '/category-maintain',
        name: 'categoryMaintain',
        component: () => import('@/views/CategoryMaintenance.vue'),
        meta: {
          title: '类别维护',
          hidden: false,
          permissionStr: ['order_category'],
        }
      },
      {
        path: '/companies-maintain',
        name: 'companiesMaintain',
        component: () => import('@/views/SystemCompaniesMaintain.vue'),
        meta: {
          title: '公司维护',
          hidden: false,
          permissionStr: ['company'],
        }
      },
      {
        path: '/companies-detail/:id',
        name: 'companiesDetail',
        component: () => import('@/views/ViewsComponents/system/CompanyDetail.vue'),
        meta: {
          title: '公司详情',
          hidden: true,
          permissionStr: ['company'],
        }
      },
      {
        path: '/companies-record',
        name: 'companiesRecord',
        component: () => import('@/views/SystemCompaniesRecord.vue'),
        meta: {
          title: '公司档案',
          hidden: false,
          permissionStr: ['company'],
        }
      },
      {
        path: '/team-maintain',
        name: 'teamMaintain',
        component: () => import('@/views/SystemTeamMaintain.vue'),
        meta: {
          title: '团队维护',
          hidden: false,
          permissionStr: ['org'],
        }
      },
      {
        path: '/salary-project',
        name: 'salaryProject',
        component: () => import('@/views/SystemSalaryProject.vue'),
        meta: {
          title: '项目维护',
          hidden: false,
          permissionStr: ['salary_project'],
        }
      },
      {
        path: '/jobs-maintain',
        name: 'jobsMaintain',
        component: () => import('@/views/SystemJobsMaintain.vue'),
        meta: {
          title: '岗位维护',
          hidden: false,
          permissionStr: ['job'],

        }
      },
      {
        path: '/rete',
        name: 'rate',
        component: () => import('@/views/Rate.vue'),
        meta: {
          title: '汇率维护',
          hidden: false,
          permissionStr: ['rate_maintenance'],

        }
      },
      {
        path: '/translator-maintain',
        name: 'translatorMaintain',
        component: () => import('@/views/TranslatorMaintain.vue'),
        meta: {
          title: '译员维护',
          hidden: false,
          permissionStr: ['translator'],

        }
      },
      {
        path: '/customer-maintain',
        name: 'customerMaintain',
        component: () => import('@/views/CustomerMaintain.vue'),
        meta: {
          title: '客户维护',
          hidden: false,
          permissionStr: ['customer_list'],

        }
      },
      {
        path: '/financial-maintain',
        name: 'financialMaintain',
        component: () => import('@/views/FinancialMaintain.vue'),
        meta: {
          title: '财务维护',
          hidden: false,
          permissionStr: ['financial_maintenance'],

        }
      },
      {
        path: '/pay-maintain',
        name: 'payMaintain',
        component: () => import('@/views/PayMaintain.vue'),
        meta: {
          title: '报销维护',
          hidden: false,
          permissionStr: ['reimburse'],
        }
      },
      {
        path: '/bank-maintenance',
        name: 'bankMaintenance',
        component: () => import('@/views/BankMaintenance.vue'),
        meta: {
          title: '银行维护',
          hidden: false,
          permissionStr: ['bank_card'],
        }
      },
      {
        path: '/budget-maintenance',
        name: 'budgetMaintenance',
        component: () => import('@/views/BudgetMaintenance.vue'),
        meta: {
          title: '预算维护',
          hidden: false,
          permissionStr: ['budget'],
        }
      },
      {
        path: '/budget-maintenance/:id',
        name: 'budgetMaintenanceDetail',
        component: () => import('@/views/BudgetMaintenanceDetail.vue'),
        meta: {
          title: '预算详情',
          hidden: true,
          permissionStr: ['budget'],
        }
      },
    ]
  },
  // 权限管理
  {
    path: '/authorization',
    component: Layout,
    name: 'AUTHORIZATION',
    meta: {
      hidden: false,
      icon: 'Setting',
      title: "权限管理",
      // permissionStr: ['resource', 'role', 'user'],
    },
    children: [
      {
        path: '/resources',
        name: 'resources',
        component: () => import('@/views/AuthorizationResources.vue'),
        meta: {
          title: '资源',
          hidden: false,
          permissionStr: ['resource'],
        }
      },
      {
        path: '/role',
        name: 'role',
        component: () => import('@/views/AuthorizationRole.vue'),
        meta: {
          title: '角色',
          hidden: false,
          permissionStr: ['role'],
        }
      },
      {
        path: '/employee/manage',
        name: 'employeeManage',
        component: () => import('@/views/AuthEmployeeManage.vue'),
        meta: {
          title: '用户管理',
          hidden: false,
          permissionStr: ['user'],

        }
      },
      {
        path: '/other-Platforms',
        name: 'otherPlatforms',
        component: () => import('@/views/OtherPlatforms.vue'),
        meta: {
          title: '其他平台权限',
          hidden: false,
          permissionStr: ['other_platform_permission'],
        }
      },

    ]
  },
  {
    path: '/background-tasks',
    component: Layout,
    name: 'BackgroundTasks',
    redirect: '/background-task/lists',
    meta: {
      hidden: false,
      icon: 'Setting',
      title: "后台",
      // permissionStr: ['resource', 'role', 'user'],
    },
    children:[
      {
        path: '/background-task/lists',
        name: 'TaskLists',
        component: () => import('@/views/BackgroundTasks.vue'),
        meta: {
          title: '后台任务',
          hidden: false,
          icon: 'TrendCharts',
          permissionStr: ['scheduled_task'],
        }
      },
    ]
  }
]



const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes: staticRoutes
})

export default router
