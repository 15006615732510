import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// 科目余额
export default {
    /**
     * @description: 查询科目余额列表(分页)
     * @return {void}
    */
    'POST/cost_translator_balances/page': (params: any, extra?: Extra) => {
        return axios(`/cost_translator_balances/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 导入科目余额
     * @return {void}
    */
    'POST/cost_translator_balances/upload': (params: any, extra?: Extra) => {
        return axios(`/cost_translator_balances/upload`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 导出科目余额
     * @return {void}
    */
    'POST/cost_translator_balances/export': (params: any, extra?: Extra) => {
        return axios(`/cost_translator_balances/export`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 单个删除id
     * @return {void}
    */
    'DELETE/cost_translator_balances/:id': (params: any, extra?: Extra) => {
        return axios(`/cost_translator_balances/${params.id}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 批量删除记录
     * @return {void}
    */
    'DELETE/cost_translator_balances/batch_delete': (params: any, extra?: Extra) => {
        return axios(`/cost_translator_balances/batch_delete`, preParams(params, extra, 'delete'))
    },
    /**
     * @description: 按批次删除
     * @return {void}
    */
    'DELETE/cost_translator_balances/import_batch_delete': (params: any, extra?: Extra) => {
        return axios(`/cost_translator_balances/import_batch_delete?importBatch=${params.importBatch}`, preParams({}, extra, 'delete'))
    },
    /**
     * @description: 应付账款(新)
     * @return {void}
    */
    'POST/cost/payable': (params: any, extra?: Extra) => {
        return axios(`/cost/payable`, preParams(params, extra, 'post'))
    },
}