import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";

export default {
  /**
   * @description: 毛利查询Page
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/gross_profit/page': (params: any, extra?: Extra) => {
    return axios(`/order/gross_profit/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 毛利监测
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/order/gross_profit/analysis': (params: any, extra?: Extra) => {
    return axios(`/order/gross_profit/analysis`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 毛利数据初始化
   * @return {void}
   */
  'GET/order/gross_profit/init': (params: any, extra?: Extra) => {
    return axios.get<void>(`/order/gross_profit/init`, preParams(params, extra, 'get'))
  },
}