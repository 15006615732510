// 提成模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";

export default {
  /**
   * @description:  通知员工确认工资 (校验)
   * @return {void}
   */
  'POST/salaries/distributes/{id}/notify/staff/validate': (params: any, extra?: Extra) => {
    return axios(`/salaries/distributes/${params.id}/notify/staff/validate`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 薪酬发放清单汇总_查询
   * @return {void}
   */
  'POST/payroll/aggregate/query': (params: any, extra?: Extra) => {
    return axios(`/payroll/aggregate/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 批量导入完结工资
   * @return {void}
   */
  'POST/salaries/import_end': (params: any, extra?: Extra) => {
    return axios(`/salaries/import_end`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 基础薪酬查询
   * @return {void}
   */
  'POST/salaries/entries': (params: any, extra?: Extra) => {
    return axios(`/salaries/entries`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 生成公司尽专金
   * @return {void}
   */
  'PUT/salaries/salary_imports/create/company_filial': (params: any, extra?: Extra) => {
    return axios(`/salaries/salary_imports/create/company_filial`, preParams(params, extra, 'put'))
  },

  /**
   * @description: 检查离职材料
   * @param {array} ids
   * @return {void}
   */
  'POST/user/material/check/resignation_file': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/user/material/check/resignation_file`, preParams(params, extra, 'post'))
  },

}